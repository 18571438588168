import { Ticket } from "../models/Ticket";

import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  PlusCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
interface ListItemProps {
  ticket: Ticket;
}

export const ListItem: React.FC<ListItemProps> = ({ ticket }) => {
  return (
    <div className="m-3 grid-cols-6 w-96">
      <a
        href="#"
        className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <p className="font-thin text-gray-700 dark:text-gray-400">
          {ticket.id}
          <UserCircleIcon className="h-6 mb-1 inline-block float-right">{ticket.userId}</UserCircleIcon>

        </p>
        <h5 className="mb-2 text-normal font-semibold tracking-tight text-gray-900 dark:text-white">
          {ticket.status === "In Progress" ? (
            <ArrowPathIcon className="h-4 mb-1 inline-block"></ArrowPathIcon>
          ) : ticket.status === "Todo" ? (
            <PlusCircleIcon className="h-4 mb-1 inline-block"></PlusCircleIcon>
          ) : (
            <CheckCircleIcon className="h-4 mb-1 inline-block"></CheckCircleIcon>
          )}
          {ticket.title}
        </h5>
        {ticket.tag.map((tag) => (
          <span
            key={tag}
            className="px-2 py-1 mb-2 mr-2 items-center text-xs font-semibold tracking-wide text-gray-600 uppercase bg-gray-200 rounded-sm dark:bg-gray-700 dark:text-gray-200"
          >
            <ExclamationCircleIcon className="h-4 mb-1 inline-block"></ExclamationCircleIcon>
            {tag}
          </span>
        ))}
      </a>
    </div>
  );
};
