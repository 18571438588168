/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./App.css";
import { ApiData } from "./ApiData";
import { ListGroup } from "./components/ListGroup";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { Ticket } from "./models/Ticket";

interface ticketData {
  tickets: Ticket[];
  section: string;
}

function App() {
  const [value, setValue] = useState(0);
  const sections: string[] = [];
  const sectionWiseData: ticketData[] = [];
  const groupByStatus = () => {
    const statusSections = [
      "Todo",
      "In Progress",
      "Done",
      "Backlog",
      "Cancelled",
    ];
    sections.length = 0;
    sections.push(...statusSections);
    const data: ticketData[] = [];
    sections.forEach((section) => {
      data.push({ tickets: [], section: section });
    });

    ApiData.tickets.forEach((ticket) => {
      data.find((d) => d.section === ticket.status)?.tickets.push(ticket);
    });
    sectionWiseData.length = 0;
    sectionWiseData.push(...data);
    console.log(data);
    setValue(value+1);
  };
  const groupByUser = () => {
    const userSections: string[] = [];
    ApiData.users.forEach((user) => {
      userSections.push(user.id);
    });
    sections.length = 0;
    sections.push(...userSections);
    const data: ticketData[] = [];
    sections.forEach((section) => {
      data.push({ tickets: [], section: section });
    });

    ApiData.tickets.forEach((ticket) => {
      data.find((d) => d.section === ticket.userId)?.tickets.push(ticket);
    });

    setValue(value+1);
  };
  const groupByPriority = () => {
    const prioritySections = ["Urgent", "High", "Medium", "Low", "No Priority"];
    sections.length = 0;
    sections.push(...prioritySections);
    const data: ticketData[] = [];
    sections.forEach((section) => {
      data.push({ tickets: [], section: section });
    });

    ApiData.tickets.forEach((ticket) => {
      data.find((d) => prioritySections[ticket.priority]=== d.section)?.tickets.push(ticket);
    });

    setValue(value+1);
  };

  return (
    <div className="App">
      <header>
        <nav>
          <div>
            <Menu as="div" className="relative inline-block text-right">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Options
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute   -0 z-30 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div className="grid-cols-2">
                          <div className="inline-block float-left p-4">
                            <span className="font-semibold text-gray-900">
                              Grouping
                            </span>
                          </div>
                          <div className="inline-block float-right p-2">
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                  Options
                                  <ChevronDownIcon
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          onClick={groupByStatus}
                                          className={`${
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                        >
                                          Status
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          onClick={groupByUser}
                                          className={`${
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                        >
                                          User
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          onClick={groupByPriority}
                                          className={`${
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                        >
                                          Priority
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div className="grid-cols-2 inline-block ">
                          <div className="inline-block float-left p-4">
                            <span className="font-semibold float-left text-left text-gray-900">
                              Ordering
                            </span>
                          </div>
                          <div className="inline-block float-right p-2">
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                  Options
                                  <ChevronDownIcon
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className={`${
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                        >
                                          Title
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className={`${
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                        >
                                          Priority
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </nav>

        <ListGroup tickets={ApiData.tickets} />
      </header>
    </div>
  );
}

export default App;
