import { Ticket } from "../models/Ticket";
import { ListItem } from "./ListItem";
interface ListGroupProps {
  tickets: Ticket[];
}

export const ListGroup: React.FC<ListGroupProps> = ({ tickets }) => {
  return (
    <div>
      {tickets.map((ticket) => (
        <ListItem key={ticket.id} ticket={ticket} />
      ))}
    </div>
  );
};